import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';

import Home from './views/home';

export default function App() {
	return (
		<Router>
			<Routes>
				<Route exact path='/' element={<Home />} />
			</Routes>
		</Router>
	);
};
