import { useState, useEffect } from 'react';

export default function Home() {

	const [products, setProducts] = useState([]);

	const getProducts = () => {
		//
	}

	useEffect(() => {
		getProducts();
	}, []);

	return (
		<>
			<nav class="navbar navbar-expand-lg navbar-light bg-light">
				<div class="container-fluid">
					<a class="navbar-brand" href="#">Navbar</a>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item">
						<a class="nav-link active" aria-current="page" href="#">Home</a>
						</li>
						<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
						</li>
						<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Dropdown
						</a>
						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><a class="dropdown-item" href="#">Action</a></li>
							<li><a class="dropdown-item" href="#">Another action</a></li>
							<li><hr class="dropdown-divider" /></li>
							<li><a class="dropdown-item" href="#">Something else here</a></li>
						</ul>
						</li>
						<li class="nav-item">
						<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
						</li>
					</ul>
					<form class="d-flex">
						<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
						<button class="btn btn-outline-success" type="submit">Search</button>
					</form>
					</div>
				</div>
			</nav>

			<div className="container-fluid bg-light min-vh-100 p-4">
				<h2>Products</h2>

				<div className="row g-3 text-center">

					{
						products.map(product => (
							<div className="col-6 col-md-4 col-lg-2">
								<div className="h-100 rounded-3 border">
									<img src="" alt="" />
									<span className="d-block fw-bold">Product Title</span>
									<small className="d-block fw-bold text-success">$300.00</small>
								</div>
							</div>
						))
					}

				</div>
			</div>
		</>
	);
};
